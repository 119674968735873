import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

type Props = {
    currentPage: number
    totalPages: number
    hideArrows?: boolean
    onNextPage: () => void
    onPreviousPage: () => void
}

function SectionPagination({
    currentPage,
    totalPages,
    hideArrows,
    onNextPage,
    onPreviousPage,
}: Props) {
    const isNextDisabled = currentPage >= totalPages - 1
    const isPreviousDisabled = currentPage <= 0

    return (
        <div className="mb-3 mt-1 hidden flex-row items-center justify-between md:flex">
            <button
                disabled={hideArrows || isPreviousDisabled}
                className={twMerge(
                    'flex size-[48px] items-center justify-center rounded-full border transition-all duration-200',
                    hideArrows ? 'opacity-0' : 'opacity-1',
                    isPreviousDisabled ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'
                )}
                onClick={onPreviousPage}
            >
                <ChevronLeftIcon height={24} className="stroke-2" />
            </button>
            <div className="mx-auto flex flex-row gap-2">
                {Array.from({ length: totalPages }, (_, i) => (
                    <div
                        key={i}
                        className={twMerge(
                            'size-2 rounded-full transition-all duration-200',
                            i === currentPage ? 'bg-sq-gray-900' : 'bg-sq-gray-200'
                        )}
                    />
                ))}
            </div>
            <button
                disabled={hideArrows || isNextDisabled}
                className={twMerge(
                    'flex size-[48px] items-center justify-center rounded-full border transition-all duration-200',
                    hideArrows ? 'opacity-0' : 'opacity-1',
                    isNextDisabled ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'
                )}
                onClick={onNextPage}
            >
                <ChevronRightIcon height={24} className="stroke-2" />
            </button>
        </div>
    )
}

export default SectionPagination
